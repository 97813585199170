import styled from "styled-components";
import * as imgs from "imgs";

import YouTube from "react-youtube";
import Slider from "react-slick";
import { useEffect, useState } from "react";

const Section01 = styled.div`
  background-image: url(${imgs.bgTop});
  background-position: top center;

  @media (max-width: 1024px) {
    background-image: url(${imgs.bgTopMobile});
    background-size: 100% auto;
  }
`;

const LeftArrow = (props: any) => {
  return (
    <imgs.arrow {...props} style={{ transform: "scale(5)", marginLeft: -20 }} />
  );
};

const RightArrow = (props: any) => {
  return (
    <imgs.arrow
      {...props}
      style={{ transform: "scale(5) scaleX(-1)", marginRight: -20 }}
    />
  );
};

function App() {
  const [slide, setSlide] = useState(0);
  const [video, setVideo] = useState<string | null>(null);

  useEffect(() => {
    document.addEventListener("keydown", (e) => {
      if (!video) return;

      if (e.key === "Escape") {
        setVideo(null);
      }
    });
  }, [video]);

  return (
    <>
      <div className="">
        <Section01 className="section-01 relative z-30 bg-no-repeat pt-8 lg:pt-16">
          <div className="container px-4">
            <div className="grid grid-cols-12">
              <div className="col-span-12 lg:col-span-10 lg:col-start-2">
                <div className="grid grid-cols-12">
                  <div className="col-span-12">
                    <div className="flex items-center justify-between">
                      <h1 className="text-sm lg:text-lg font-bold text-white">
                        CYBERPSICOLOGIA E MARKETING ONLINE
                      </h1>

                      <a
                        href="https://amzn.to/3JUt2x5"
                        target="_blank"
                        rel="noreferrer"
                        className="hidden lg:block bg-white px-7 py-4 text-[#272D3E] font-bold rounded-12xl transition hover:scale-110"
                      >
                        Compre o livro e garanta seu curso gratuito
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="container px-4">
            <div className="grid grid-cols-12">
              <div className="col-span-12 lg:col-span-10 lg:col-start-2">
                <div className="grid grid-cols-12">
                  <div className="col-span-12 lg:col-span-6 pt-4 lg:pt-24">
                    <div className="flex flex-col gap-4 lg:gap-6">
                      <h2 className="lg:leading-[52px] text-xl lg:text-7xl text-white font-sans">
                        Descubra os segredos da Cyberpsicologia e turbine suas
                        vendas online!
                      </h2>

                      <h3 className="lg:leading-[30px] text-white text-md lg:text-xl">
                        Compre o livro Cyberpsicologia na pré-venda da Amazon e
                        ganhe um curso completo para você dominar o poder da{" "}
                        {` `}
                        <span className="text-[#40BFE7] ">
                          Cyberpsicologia no Marketing Digital!
                        </span>
                      </h3>
                    </div>
                  </div>

                  <div className="col-span-12 lg:col-span-6">
                    <div className="flex justify-center lg:justify-end">
                      <img
                        src={imgs.bookTop}
                        alt=""
                        className="w-6/12 lg:w-11/12 relative -mb-8 lg:-mb-0 lg:top-14 mt-6 lg:mt-0"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Section01>

        <div className="container px-4 lg:px-14 pt-12 lg:pt-24 pb-10 lg:pb-40">
          <div className="mx-auto lg:w-2/3 text-xl lg:text-7xl font-bold text-center">
            <h2 className="text-[#272753]">Treinamento Curso Online do Zero</h2>
            <h2 className="text-[#4B498A]">De R$497,00 por R$0,00</h2>
          </div>

          <div className="flex justify-center mt-4 lg:mt-10">
            <a
              href="https://amzn.to/3JUt2x5"
              target="_blank"
              rel="noreferrer"
              className="bg-[#40BFE7] px-6 py-3 lg:px-7 lg:py-4 text-[#F4F7FF] font-bold rounded-12xl transition hover:scale-110"
              style={{
                boxShadow: "0px 15px 30px rgba(0, 186, 199, 0.33)",
              }}
            >
              Garanta seu curso gratuito
            </a>
          </div>
        </div>

        <div className="bg-[#EDFCFD] py-10 lg:py-20">
          <div className="container px-4 lg:px-14">
            <div className="grid grid-cols-12">
              <div className="col-span-12 lg:col-span-10 lg:col-start-2">
                <div className="grid grid-cols-12 gap-6 lg:gap-20">
                  <div className="col-span-12 lg:col-span-6">
                    <div className="flex flex-col gap-2 justify-center h-full">
                      <h3 className="text-[#40BFE7] text-base font-bold tracking-widest">
                        O AUTOR
                      </h3>

                      <h2 className="text-[#272753] font-medium text-2xl lg:text-3xl">
                        Olá! Eu sou Rodrigo Volponi
                      </h2>

                      <h4 className="text-[#272D3E] font-normal text-xl">
                        Mestre em Cyberpsicologia e especialista em vendas
                        online. E estou prestes a revelar a você a chave mestra
                        para criar ou expandir seu negócio digital de forma
                        exponencial. Acredite, você não vai querer perder essa
                        oportunidade!
                      </h4>
                    </div>
                  </div>

                  <div className="col-span-12 lg:col-span-6">
                    <img src={imgs.rodrigo} alt="" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="bg-[#272753] py-14 lg:py-20">
          <div className="container px-4 lg:px-14">
            <div className="flex flex-col lg:flex-row items-center gap-10 lg:gap-20">
              <div className="lg:-mb-40 order-2 lg:order-1">
                <div className="hidden lg:block">
                  <imgs.Book className="w-8/12 lg:w-auto -mb-[118px] lg:-mb-0 lg:h-[450px]" />
                </div>

                <div className="block lg:hidden">
                  <img
                    src={imgs.imgBook}
                    className="w-8/12 lg:w-auto -mb-[118px] lg:-mb-0 lg:h-[400px]"
                  />
                </div>
              </div>

              <div className="order-1 lg:order-2 lg:w-8/12 lg:pr-10">
                <p className="text-white text-xl lg:text-2xl font-medium leading-relaxed lg:leading-loose">
                  Imagine ter em suas mãos um livro que une a ciência da
                  Cyberpsicologia e o Marketing Online, e ainda ganhar um curso
                  gratuito que vale R$497,00! Parece bom demais para ser
                  verdade? Pois é exatamente isso que estou oferecendo a você.
                </p>
              </div>
            </div>
          </div>
        </div>

        <div className="container px-4 lg:px-14 mt-24 mb-20 lg:mt-40 lg:mb-40">
          <div className="lg:w-2/5 mx-auto">
            <h2 className="text-[#272753] font-medium text-2xl lg:text-3xl">
              Então, como aproveitar essa oferta incrível? É simples! Basta
              seguir o passo a passo abaixo:
            </h2>

            <div className="flex flex-col gap-10 mt-10">
              {[
                "Compre o livro Cyberpsicologia na pré-venda da Amazon.",
                "Tire um print do comprovante de compra.",
                "Envie o print, seu nome completo e e-mail para o inbox do meu Instagram (@volponirodrigo).",
                "Pronto! Em poucas horas você terá acesso ao curso gratuito sobre Cyberpsicologia, no valor de R$ 497,00.",
              ].map((item, index) => (
                <div className="flex gap-4 items-start" key={index}>
                  <div className="flex-shrink-0 text-white font-medium text-lg bg-[#40BFE7] rounded-full w-10 h-10 flex justify-center items-center mt-1">
                    {index + 1}
                  </div>
                  <h3 className="text-[#272D3ECC] font-normal text-lg lg:text-xl">
                    {item}
                  </h3>
                </div>
              ))}
            </div>
          </div>
        </div>

        <div className="bg-[#EDFCFD] w-11/12 2xl:w-10/12 mx-auto rounded-4xl px-2 lg:px-7 pt-10 lg:pt-20 pb-40 lg:pb-4">
          <div className="container px-4 lg:px-14">
            <div className="grid grid-cols-12 relative z-10">
              <div
                className="
              absolute
              -left-24
              top-full
              col-span-6
              lg:hidden
              w-full
            "
              >
                <div className="flex h-full">
                  <img
                    src={imgs.Mockup}
                    className="self-end w-full scale-150"
                  />
                </div>
              </div>

              <div
                className="
              hidden
              lg:block
              -mx-32
              col-span-6
            "
              >
                <div className="flex h-full">
                  <img src={imgs.Mockup} alt="" className="self-end" />
                </div>
              </div>

              <div className="col-span-12 lg:col-span-6 order-1 z-20">
                <div className="flex flex-col gap-6">
                  <h2 className="text-[#272753] text-3xl font-medium">
                    Com o livro e o curso, você:
                  </h2>

                  {[
                    {
                      img: imgs.Brain,
                      desc: "Entenderá os fundamentos da psicologia em vendas online.",
                    },
                    {
                      img: imgs.chart,
                      desc: "Criará um negócio do zero ou expandirá seu negócio atual exponencialmente.",
                    },
                    {
                      img: imgs.polar,
                      desc: "Dominará as técnicas de Cyberpsicologia e Marketing Digital.",
                    },
                  ].map((item, index) => (
                    <div
                      key={index}
                      className="bg-white py-8 px-6 lg:px-8 2xl:px-10 rounded-2xl flex gap-2 lg:gap-6 shadow-xl"
                    >
                      <div>
                        <item.img />
                      </div>
                      <h5 className="text-lg lg:text-xl font-normal text-[#272D3ECC]">
                        {item.desc}
                      </h5>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="my-20 lg:my-28">
          <h2 className="text-[#272753] font-medium text-2xl lg:text-3xl w-10/12 lg:w-7/12 mx-auto text-center">
            Não acredita em mim?{" "}
            <a
              href="https://webmentoring.com.br/"
              target="_blank"
              rel="noreferrer"
              className="underline"
            >
              A Web Mentoring
            </a>
            , empresa de lançamentos de cursos online que eu fundei, já
            movimentou dezenas de milhões de reais pela internet!
          </h2>
        </div>

        <div className="px-4 lg:px-14 bg-[#272753] py-16 lg:py-28">
          <div className="lg:w-6/12 mx-auto flex flex-col gap-4">
            <h2 className="text-[#40BFE7] font-bold uppercase text-center">
              DEPOIMENTOS
            </h2>
            <h3 className="text-white font-medium text-2xl lg:text-3xl text-center">
              Enfim… essas pessoas sempre acreditaram em mim e eu confio nelas
            </h3>
          </div>

          <div className="-mx-4 lg:-mx-0 lg:px-20 my-10">
            <Slider
              dots={true}
              customPaging={(i) => (
                <div
                  className={`${
                    i === slide ? "bg-[#00BAC7]" : "bg-[#FFFFFF1A]"
                  } w-3 h-3 rounded-full mt-4 transition`}
                ></div>
              )}
              beforeChange={(current, next) => setSlide(next)}
              slidesToScroll={1}
              slidesToShow={4}
              prevArrow={<LeftArrow />}
              nextArrow={<RightArrow />}
              swipeToSlide={true}
              responsive={[
                {
                  breakpoint: 1024,
                  settings: {
                    slidesToShow: 3,

                    infinite: true,
                    dots: true,
                  },
                },
                {
                  breakpoint: 480,
                  settings: {
                    arrows: false,
                    centerMode: true,
                    slidesToShow: 1,
                  },
                },
              ]}
            >
              {[
                { url: "TTAHlfpGwFs" },
                { url: "98pbNo7pjFs" },
                { url: "vYlpKq5R2uA" },
                { url: "Y_gaLQyrKiE" },
                { url: "Jrdz0KC6wSU" },
                { url: "ViYe76X9puA" },
                { url: "zxgIQosKnug" },
              ].map((item, index) => (
                <div key={index} className="p-2">
                  <div
                    className="relative group cursor-pointer h-80"
                    onClick={() => setVideo(item.url)}
                  >
                    <YouTube
                      className="max-w-full w-full h-full pointer-events-none"
                      videoId={item.url}
                    />
                  </div>
                </div>
              ))}
            </Slider>
          </div>
        </div>

        <div className="container px-4 lg:px-14 mt-20 lg:mt-28 lg:mb-14">
          <div className="grid grid-cols-12">
            <div className="col-span-12 lg:col-span-10 lg:col-start-2">
              <div className="grid grid-cols-12 gap-4 lg:gap-20">
                <div className="col-span-12 lg:col-span-6 order-2 lg:order-1">
                  <img src={imgs.fire} alt="" />
                </div>

                <div className="col-span-12 lg:col-span-6 order-1 lg:order-2">
                  <div className="flex flex-col gap-4 h-full justify-center">
                    <h3 className="text-[#40BFE7] text-lg font-bold uppercase tracking-widest">
                      Agora me responda
                    </h3>
                    <h2 className="text-[#272753] text-2xl lg:text-3xl font-medium">
                      Você está pronto para transformar sua vida e seus
                      negócios?
                    </h2>
                    <div className="text-[#272D3ECC] text-lg lg:text-xl">
                      Então, não perca tempo! Essa oferta exclusiva é por tempo
                      limitado.
                    </div>
                  </div>
                </div>
              </div>

              <div className="grid grid-cols-12 mt-10 lg:mt-20 gap-4">
                <div className="col-span-12 lg:col-span-6">
                  <div className="flex flex-col gap-4 lg:gap-8 h-full">
                    <h2 className="text-[#272753] text-3xl font-medium">
                      Sobre o livro
                    </h2>

                    <div className="text-[#272D3ECC] text-lg flex flex-col gap-4">
                      <p>
                        O livro Cyberpsicologia oferece uma visão completa dessa
                        relação e mostra, passo a passo, como criar ou evoluir
                        um negócio digital. Além disso, com o curso gratuito,
                        você aprenderá como{" "}
                        <strong>CRIAR UM CURSO ONLINE DO ZERO</strong>.
                      </p>

                      <p>
                        Lembre-se: o sucesso do seu negócio digital está a
                        apenas alguns cliques de distância. Então, o que está
                        esperando? Compre o livro Cyberpsicologia na pré-venda
                        da Amazon e transforme seu negócio agora mesmo!
                      </p>
                    </div>

                    <a
                      className="bg-[#40BFE7] px-4 lg:px-7 py-4 text-[#F4F7FF] font-bold rounded-12xl transition hover:scale-110 self-start"
                      style={{
                        boxShadow: "0px 15px 30px rgba(0, 186, 199, 0.33)",
                      }}
                      href="https://amzn.to/3JUt2x5"
                      target="_blank"
                      rel="noreferrer"
                    >
                      Compre e garanta seu curso gratuito
                    </a>
                  </div>
                </div>

                <div className="col-span-12 lg:col-span-6">
                  <img src={imgs.book} alt="" />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="w-full h-4 shadow-lg"></div>

        <div className="py-20 flex flex-col gap-10">
          <h2 className="text-[#272753] font-bold text-6xl text-center tracking-widest">
            PARCEIROS
          </h2>

          <div className="container px-4 lg:px-14">
            <div className="grid grid-cols-12">
              <div className="col-span-12 lg:col-span-8 lg:col-start-3">
                <div className="grid grid-cols-12 gap-4 lg:gap-10 items-center">
                  <div className="col-span-4">
                    <a
                      href="https://cademi.com.br/"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <img src={imgs.cademi} alt="" />
                    </a>
                  </div>
                  <div className="col-span-4">
                    <a
                      href="https://enotas.com.br/"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <img src={imgs.enotas} alt="" />
                    </a>
                  </div>
                  <div className="col-span-4">
                    <a
                      href="https://hotmart.com/"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <img src={imgs.hotmart} alt="" />
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="container px-4 lg:px-14 xl:mt-24">
          <div className="grid grid-cols-12">
            <div className="col-span-12 lg:col-span-10 lg:col-start-2">
              <div className="grid grid-cols-12">
                <div className="col-span-12">
                  <div className="mx-auto px-4 lg:px-14 bg-[#272753] py-10 lg:py-20 rounded-3xl mb-10 lg:mb-20">
                    <div className="mx-auto flex flex-col gap-4">
                      <h3 className="text-white font-medium text-lg lg:text-2xl text-center">
                        ⚠️ Lembre-se, envie o print do comprovante de compra,
                        seu nome completo e e-mail para o meu Instagram (
                        <a
                          href="https://www.instagram.com/volponirodrigo/"
                          target="_blank"
                          rel="noreferrer"
                          className="underline"
                        >
                          @volponirodrigo
                        </a>
                        ) para garantir o curso gratuito.
                      </h3>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="container px-4 lg:px-14 pb-20">
          <div className="grid grid-cols-12">
            <div className="col-span-12 lg:col-span-10 lg:col-start-2">
              <div className="grid grid-cols-12">
                <div className="col-span-12 lg:col-span-6">
                  <div className="flex flex-col gap-4">
                    <h2 className="text-[#272753] text-3xl font-medium">
                      Não perca a chance de revolucionar o seu negócio digital.
                    </h2>

                    <h3 className="text-[#272D3ECC] text-xl">
                      Atenciosamente, <br />
                      Rodrigo Volponi
                    </h3>

                    <img src={imgs.Signature} className="h-24 mr-auto" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {video && (
          <div className="fixed top-0 left-0 w-full h-full z-30 flex px-4 ">
            <div
              className="absolute top-0 left-0 w-full h-full bg-black opacity-80 cursor-pointer"
              onClick={() => setVideo(null)}
            ></div>

            <div className="bg-white mx-auto rounded-2xl px-4 pt-14 pb-4 z-10 self-center relative">
              <button
                className="px-2 py-1 bg-black absolute top-4 right-4 text-white rounded-xl text-sm shadow-lg"
                onClick={() => setVideo(null)}
              >
                FECHAR
              </button>

              <YouTube
                className="pointer-events-none"
                videoId={video}
                opts={{
                  playerVars: {
                    // https://developers.google.com/youtube/player_parameters
                    autoplay: 1,
                  },
                }}
              />
            </div>
          </div>
        )}
      </div>
    </>
  );
}

export default App;
